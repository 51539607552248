.calendar-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.calendar-scroll-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.table-calendar-container {
  min-width: 100%;
  /* width: 90rem; */
}

/* Table styles */
.table-calendar {
  border-collapse: separate;
  border-spacing: 0;
  width: auto !important;
  table-layout: fixed;
}

.table-calendar .p-datatable-wrapper {
  overflow: unset;
}
.table-calendar .p-datatable-wrapper {
  width: 77rem;
}

/* Table header and cell styles */
.table-calendar th,
.table-calendar td {
  border: 1px solid #dee2e6;
  padding: 0.5rem;
  position: relative;
  width: 80px;
  min-width: 80px;
}

.table-calendar th:first-child,
.table-calendar td:first-child {
  width: 250px;
  min-width: 250px;
}

.table-calendar th {
  font-size: 11px;
}

/* Group header styles */
.group-header-cell {
  @apply bg-medium-aquamarine;
  /* background-color: #c8bfe7; */
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 100%;
  padding: 0 !important;
}

.group-header {
  height: 30px !important;
}

.group-header-icon {
  @apply bg-orange;
  margin-right: 5px;
  /* background-color: #66dac3; */
  margin-bottom: 7px;
  color: white;
}

/* Property row and cell styles */
.property-row {
  height: 70px;
}

.property-cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2px 10px;
  gap: 20px;
}

.property-icon {
  color: #666;
  margin-right: 5px;
}

.property-name {
  font-weight: bold;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Date cell styles */
.date-cell {
  position: relative;
  height: 100%;
  padding: 2px;
}

.date-cell-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.price-stay-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Event styles */
.event-container {
  position: relative;
  height: 87px;
  width: 100%;
}

.event-cell {
  position: absolute;
  /* height: 26px; */
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8em;
  color: black;
  padding: 2px 4px;
  border-radius: 2px;
  z-index: 1;
  min-width: 50%;
  cursor: pointer;
}

.event-duration,
.event-checkin,
.event-checkout,
.event-cleaning {
  font-size: 0.8em;
}

.reservation-event {
  position: absolute;
  height: 26px;
  bottom: 2px;
  left: 0;
  right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.7em;
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
  cursor: pointer;
}

/* Calendar header cell styles */
.calendar-header-cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 34px;
}

.calendar-header-cell::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 1px dashed #999;
}

/* Responsive styles */
@media (max-width: 768px) {
  .calendar-header-cell,
  .calendar-cell {
    padding: 5px;
    font-size: 12px;
  }

  .property-name {
    padding: 5px;
    font-size: 12px;
  }

  .calendar-event {
    font-size: 10px;
  }
}

/* Region row styles */
.table-calendar .region-row {
  height: 35px;
}

@media (max-width: 1200px) {
  .table-calendar th,
  .table-calendar td {
    width: 70px;
    min-width: 70px;
  }
}

@media (max-width: 992px) {
  .table-calendar th,
  .table-calendar td {
    width: 60px;
    min-width: 60px;
  }
}

.calendar-wrapper {
  width: 100%;
  overflow: hidden;
}

.table-calendar-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.date-cell {
  border-right: 1px solid #dee2e6;
  height: 100%;
}

.calendar-header-cell {
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  justify-content: center;
}
